import React from "react";

const CounterOne = () => {
  return (
    <div className="counter-area-1 space-bottom">
      <div className="container">
        <div className="counter-wrap1">
          <div className="row gx-0 align-items-center">
          <div className="col-lg-6">
              <div
                className="counter-checklist-wrap"
                style={{
                  backgroundImage:
                    "url(assets/img/bg/counter-checklist-bg.png)",
                }}
              >
                <div className="checklist style-white mb-50">
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      7/24 Yol Yardım
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Yıkama / Yağlama
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Ağır İş Makinesi ve Kamyon Lastik Tamir
                    </li>
                  </ul>
                </div>
                <div className="call-media-wrap">
                  <div className="icon">
                    <img src="assets/img/icon/phone-1.svg" alt="Altun Oto Lastik" />
                  </div>
                  <div className="media-body">
                    <h6 className="title text-white">7/24 Ulaşın</h6>
                    <h4 className="link">
                      <a className="text-white" href="tel:+905357455407">
                      (0535)745-5407
                      </a>
                    </h4>
                    <h4 className="link">
                      <a className="text-white" href="tel:+905438783435">
                      (0543)878-3435
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="counter-card-wrap">
                <div className="row gy-30 justify-content-center">
                  <img src="\assets\img/logo.png" alt="logo" style={{maxWidth:"416px"}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterOne;
