import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as contentful from "contentful";

const BlogAreaList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Contentful client'ı oluştur
  const client = contentful.createClient({
    space: "0vfequ3akvnl",
    accessToken: "PBD_ZejK6jeP7wyUu3r39EeHqO0VQUtdYTxcsHElJGs",
  });

  // Blog içeriklerini çekmek için useEffect kullan
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await client.getEntries({
          content_type: "pageBlogPost",
        });
        setPosts(response.items);
        console.log(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const getImageUrl = (image) => {
    return image ? `https:${image.fields.file.url}` : "";
  };

  if (loading) return <div>Yükleniyor...</div>;
  if (error) return <div>Hata: {error.message}</div>;

  return (
    <section className="blog-area space-top space-extra-bottom">
      <div className="container">
        <div className="row gx-40">
          <div className="col-xxl-12 col-lg-12">
            {posts.length === 0 ? (
              <p>Hiç blog yazısı bulunamadı.</p>
            ) : (
              posts.map((post) => (
                <div className="blog-single-card" key={post.sys.id}>
                  <div className="blog-thumb">
                    {post.fields.featuredImage && (
                      <img
                        src={getImageUrl(post.fields.featuredImage)}
                        alt={post.fields.title}
                      />
                    )}
                  </div>
                  <div className="blog-content">
                    <div className="blog-meta">
                      <Link to="/blog">
                        <i className="fas fa-user" />
                        Altun Oto Lastik
                      </Link>
                      {/* <Link to="/blog">
                        <i className="fas fa-comments" />
                        Comments (05)
                      </Link> */}
                    </div>
                    <h3 className="blog-title">
                      <Link to="/blog-details">{post.fields.title}</Link>
                    </h3>
                    <p className="blog-text">
                      {post.fields.content.content[0]?.content[0]?.value ||
                        "İçerik mevcut değil."}{" "}
                    </p>
                    {/* <Link to="/blog-details" className="btn style-border2">
                      {" "}
                      DETAY <i className="fas fa-arrow-right" />
                    </Link> */}
                    <div className="blog-date">
                      <Link to="/blog">
                        <span>
                          {new Date(post.fields.publishedDate).getDate()}
                        </span>
                        <span>
                          {new Date(post.fields.publishedDate).toLocaleString(
                            "tr-TR",
                            { month: "long" }
                          )}
                        </span>
                        <span>
                          {new Date(post.fields.publishedDate).getFullYear()}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            )}

            {/* <div className="pagination justify-content-center">
              <ul>
                <li>
                  <Link to="/blog">1</Link>
                </li>
                <li>
                  <Link to="/blog">2</Link>
                </li>
                <li>
                  <Link to="/blog">3</Link>
                </li>
                <li>
                  <Link to="/blog">
                    <i className="fas fa-angle-right" />
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
          {/* <div className="col-xxl-4 col-lg-5">
            <aside className="sidebar-area">
              <div className="widget widget_search">
                <h3 className="widget_title">Search</h3>
                <form className="search-form">
                  <input type="text" placeholder="Search" />
                  <button type="submit">
                    <i className="fas fa-search" />
                  </button>
                </form>
              </div>
              <div className="widget widget_categories">
                <h3 className="widget_title">Category</h3>
                <ul>
                  <li>
                    <Link to="/blog">
                      Precision Auto Works <span>(4)</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog">
                      Mobile Car Repair <span>(5)</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog">
                      Elite Automotive Service <span>(8)</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog">
                      Pro Auto Fix <span>(4)</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog">
                      Drive-In Garage <span>(3)</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="widget">
                <h3 className="widget_title">Popular post</h3>
                <div className="recent-post-wrap">
                  <div className="recent-post">
                    <div className="media-body">
                      <h4 className="post-title">
                        <Link className="text-inherit" to="/blog-details">
                          Quick Fix Motors
                        </Link>
                      </h4>
                      <div className="recent-post-meta">
                        <Link to="/blog">Sep 15, 2024</Link>
                      </div>
                    </div>
                    <div className="media-img">
                      <Link to="/blog-details">
                        <img
                          src="assets/img/blog/recent-post1.png"
                          alt="Altun Oto Lastik"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post">
                    <div className="media-body">
                      <h4 className="post-title">
                        <Link className="text-inherit" to="/blog-details">
                          Professional Service
                        </Link>
                      </h4>
                      <div className="recent-post-meta">
                        <Link to="/blog">Jun 25, 2024</Link>
                      </div>
                    </div>
                    <div className="media-img">
                      <Link to="/blog-details">
                        <img
                          src="assets/img/blog/recent-post2.png"
                          alt="Altun Oto Lastik"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post">
                    <div className="media-body">
                      <h4 className="post-title">
                        <Link className="text-inherit" to="/blog-details">
                          Gear Wrench Auto
                        </Link>
                      </h4>
                      <div className="recent-post-meta">
                        <Link to="/blog">Jun 25, 2024</Link>
                      </div>
                    </div>
                    <div className="media-img">
                      <Link to="/blog-details">
                        <img
                          src="assets/img/blog/recent-post3.png"
                          alt="Altun Oto Lastik"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="widget widget_tag_cloud">
                <h3 className="widget_title">Tags</h3>
                <div className="tagcloud">
                  <Link to="/blog">Mechanic</Link>
                  <Link to="/blog">Auto Fixers</Link>
                  <Link to="/blog">Car Fix</Link>
                  <Link to="/blog">Pro Auto Fix</Link>
                  <Link to="/blog">Service</Link>
                  <Link to="/blog">Auto Fix</Link>
                  <Link to="/blog">Auto Repair</Link>
                  <Link to="/blog">Masters</Link>
                  <Link to="/blog">Automotive</Link>
                </div>
              </div>
            </aside>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default BlogAreaList;
