import React from "react";
import { Link } from "react-router-dom";

const SubscribeOne = () => {
  return (
    <div className="container">
      <div
        className="footer-top-1 bg-theme"
        style={{ backgroundImage: "url(assets/img/bg/footer-top-bg1-1.png)" }}
      >
        <div className="footer-logo">
          <Link to="/">
            <img src="assets/img/altunoto-lastik-logo-white2.png" alt="Altun Oto Lastik" />
          </Link>
        </div>
        <div className="call-media-wrap">
          <div className="icon">
            <img src="assets/img/icon/phone-1.svg" alt="Altun Oto Lastik" />
          </div>
          <div className="media-body">
            <h6 className="title text-white">7/24 Yol Yardım</h6>
            <h4 className="link">
              <a className="text-white" href="tel:+905357455407">
                (535) 745-5407
              </a>
            </h4>
            <h4 className="link">
              <a className="text-white" href="tel:+905438783435">
                (543) 878-3435
              </a>
            </h4>
          </div>
        </div>
        <div className="social-btn">
          <a href="https://www.facebook.com/profile.php?id=100032739833072">
            <i className="fab fa-facebook-f" />
          </a>
          <a href="https://wa.me/905438783435/?text=Merhaba, ">
            <i className="fab fa-whatsapp" />
          </a>
          <a href="https://www.instagram.com/altunotolastik">
            <i className="fab fa-instagram" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SubscribeOne;
