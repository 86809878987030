import React, { useEffect, useState } from 'react';
import * as contentful from 'contentful';

const ContentfulBlog = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Contentful client'ı oluştur
    const client = contentful.createClient({
        space: '0vfequ3akvnl',
        accessToken: 'PBD_ZejK6jeP7wyUu3r39EeHqO0VQUtdYTxcsHElJGs'
    });

    // Blog içeriklerini çekmek için useEffect kullan
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await client.getEntries({
                    content_type: 'pageBlogPost'
                });
                setPosts(response.items);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    const getImageUrl = (image) => {
        return image ? `https:${image.fields.file.url}` : '';
    };

    if (loading) return <div>Yükleniyor...</div>;
    if (error) return <div>Hata: {error.message}</div>;

    return (
        <div>
            <h1>Blog Yazıları</h1>
            {posts.length === 0 ? (
                <p>Hiç blog yazısı bulunamadı.</p>
            ) : (
                posts.map((post) => (
                    <div key={post.sys.id}>
                        <h2>{post.fields.title}</h2>
                        <h3>{new Date(post.fields.publishedDate).toLocaleDateString()}</h3>
                        {post.fields.featuredImage && (
                            <img src={getImageUrl(post.fields.featuredImage)} alt={post.fields.title} />
                        )}
                        <p>{post.fields.content.content[0]?.content[0]?.value || 'İçerik mevcut değil.'}</p>
                    </div>
                ))
            )}
        </div>
    );
};

export default ContentfulBlog;
