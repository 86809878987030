import React from "react";
import { Link } from "react-router-dom";

const HeroOne = () => {
  return (
    <div
      className="hero-wrapper hero-1"
      id="hero"
      style={{ backgroundImage: "url(assets/img/hero/hero_bg_1_1.png)" }}
    >
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-xl-6">
            <div className="hero-style1">
              <span className="sub-title text-white">Kalİtelİ İşçİlİk</span>
              <h1 className="hero-title text-white">
                {" "}
                <span>
                  <img src="assets/img/hero/hero_shape_1.png" alt="Altun Oto Lastik" />
                  Altun Oto Lastik
                </span>{" "}
                Mükemmel Hizmet
              </h1>
              <p className="hero-text text-white">
                7/24 Yol Yardım Hizmeti
              </p>
              <div className="btn-group">
                <Link to="#" className="btn">
                  Hakkımızda
                </Link>
                <Link to="#" className="btn style-border">
                  Hizmetlerimiz
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="hero-thumb text-center">
              <img src="assets/img/hero/hero_thumb_1_1.png" alt="Altun Oto Lastik" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroOne;
