import React from "react";
import { Link } from "react-router-dom";

const FaqAreaOne = () => {
  return (
    <section className="faq-area-1 space-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="title-area mb-lg-0">
              <span className="sub-title">Aklınızdaki Sorular</span>
              <h2 className="sec-title">
                Lastik Tamirinin Güvenliği ve Kalıcılığı Nasıl Sağlanır?
              </h2>
              <p className="sec-text">
                Lastik tamiri, aracın güvenliği açısından büyük önem taşır, bu
                nedenle tamirin doğru ve güvenilir şekilde yapılması gereklidir.
                Lastikte oluşan hasarın büyüklüğü ve konumu tamirin güvenli olup
                olmadığını belirler. Örneğin, lastiğin yanağındaki hasarlar
                tamir edilmesi zor olan ve çoğu zaman güvenlik açısından risk
                taşıyan bölgelerdir. Lastik tamirinde kullanılan malzemeler ve
                teknikler de tamirin kalıcılığını etkiler. Profesyonel lastik
                tamiri genellikle bir mantar veya fiş ile yapılır, ancak daha
                derin hasarlar için lastiğin iç yüzeyine yama uygulanması
                gerekebilir. Ayrıca tamir sonrası lastiğin balans ayarının
                yapılması, tamirin uzun vadeli etkisini artırır.
              </p>
              <div className="btn-wrap mt-30">
                <Link className="btn style2" to="#">
                  Detay <i className="fas fa-arrow-right ms-2" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="accordion-area accordion" id="faqAccordion">
              <div className="accordion-card active">
                <div className="accordion-header" id="collapse-item-1">
                  <button
                    className="accordion-button "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-1"
                    aria-expanded="true"
                    aria-controls="collapse-1"
                  >
                    {" "}
                    Lastik Onarımının Ne Zaman ve Hangi Durumlarda Uygulanması
                    Uygundur?
                  </button>
                </div>
                <div
                  id="collapse-1"
                  className="accordion-collapse collapse show"
                  aria-labelledby="collapse-item-1"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-body">
                    <p className="faq-text">
                      Lastik onarımı, lastiğin hasar görmesi durumunda tamirle
                      düzeltilmesi mümkün olduğunda başvurulan bir yöntemdir.
                      Ancak her lastik hasarı onarıma uygun değildir. Örneğin,
                      diş derinliği yeterince azalmış veya yanak kısmında ciddi
                      hasarlar bulunan lastiklerin onarılması önerilmez. Lastik
                      onarımı, deliklerin küçük olduğu ve hasarın lastiğin sırt
                      kısmında yer aldığı durumlarda uygulanabilir. Delik
                      çapının genellikle 6 mm'yi aşmaması gerektiği kabul
                      edilir. Ayrıca, hasarın çivi gibi kesici nesnelerden
                      kaynaklanması durumunda onarım daha güvenli olabilir.
                      Ancak ciddi yanak yaralanmaları, aşırı aşınma veya yapısal
                      bozulma durumunda lastik tamamen değiştirilmelidir.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-card ">
                <div className="accordion-header" id="collapse-item-2">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-2"
                    aria-expanded="false"
                    aria-controls="collapse-2"
                  >
                    {" "}
                    Düzenli Lastik Bakımı Neden Hayati Öneme Sahiptir?
                  </button>
                </div>
                <div
                  id="collapse-2"
                  className="accordion-collapse collapse "
                  aria-labelledby="collapse-item-2"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-body">
                    <p className="faq-text">
                      Lastik bakımı, aracın genel performansı, yol tutuşu ve
                      güvenliği üzerinde doğrudan etkili olduğu için ihmal
                      edilmemesi gereken bir konudur. Düzenli lastik bakımının
                      en önemli adımlarından biri lastik basıncının düzenli
                      olarak kontrol edilmesidir. Lastiklerin uygun basınçta
                      olmaması, hem yakıt tüketimini artırabilir hem de
                      lastiklerin ömrünü kısaltabilir. Aynı zamanda dengesiz
                      hava basıncı, aracın yolda tutunmasını zorlaştırarak
                      güvenlik riskleri oluşturabilir. Lastiklerin diş derinliği
                      de düzenli olarak kontrol edilmelidir; yasal sınır
                      genellikle 1.6 mm olsa da, güvenlik açısından bu sınırın
                      üzerindeki diş derinlikleri tavsiye edilir. Ayrıca
                      lastiklerin düzenli olarak rotasyon yapılması, aşınmanın
                      dengeli olmasını sağlayarak lastik ömrünü uzatır.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-card ">
                <div className="accordion-header" id="collapse-item-3">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-3"
                    aria-expanded="false"
                    aria-controls="collapse-3"
                  >
                    {" "}
                    Lastiklerdeki Düzensiz Aşınma ve Nedenleri Nelerdir?
                  </button>
                </div>
                <div
                  id="collapse-3"
                  className="accordion-collapse collapse "
                  aria-labelledby="collapse-item-3"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-body">
                    <p className="faq-text">
                      Lastiklerde düzensiz aşınma, aracın performansı ve
                      güvenliği açısından olumsuz sonuçlar doğurabilir. Düzensiz
                      aşınmanın en yaygın nedenlerinden biri yanlış lastik
                      basıncıdır; aşırı şişirilmiş lastikler orta kısımdan, az
                      şişirilmiş lastikler ise kenarlardan aşınma eğilimindedir.
                      Rot ayarının bozuk olması da lastiklerin düzensiz
                      aşınmasına yol açabilir. Bu durumda, lastiğin bir tarafı
                      daha hızlı aşınırken diğer tarafı nispeten sağlam
                      kalabilir. Ayrıca, aracın süspansiyon sisteminde bir sorun
                      varsa veya araç sürekli olarak dengesiz yük taşıyorsa bu
                      da lastik aşınmasına neden olabilir. Düzensiz aşınmayı
                      önlemek için lastiklerin balans ayarlarının düzenli olarak
                      kontrol edilmesi, doğru hava basıncının sağlanması ve
                      rotasyon işleminin yapılması önemlidir.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-card ">
                <div className="accordion-header" id="collapse-item-4">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-4"
                    aria-expanded="false"
                    aria-controls="collapse-4"
                  >
                    {" "}
                    Mevsimsel Lastik Değişiminin Faydaları Nelerdir ve Ne Zaman
                    Yapılmalıdır?
                  </button>
                </div>
                <div
                  id="collapse-4"
                  className="accordion-collapse collapse "
                  aria-labelledby="collapse-item-4"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-body">
                    <p className="faq-text">
                      Mevsimsel lastik değişimi, özellikle kış ve yaz lastikleri
                      arasında geçiş yapılmasını içerir ve aracın mevsime uygun
                      lastiklerle donatılmasını sağlar. Kış lastikleri, soğuk
                      hava koşullarında ve kar üzerinde maksimum yol tutuşunu
                      sağlamak için tasarlanmışken, yaz lastikleri sıcak ve kuru
                      yol koşullarında en iyi performansı sunar. Bu geçişi
                      zamanında yapmak, lastiklerin aşınmasını en aza indirir ve
                      aracın yol tutuşunu iyileştirir. Kış lastikleri genellikle
                      sıcaklık 7°C'nin altına düştüğünde takılmalıdır, çünkü bu
                      sıcaklıkların altındaki koşullarda yaz lastiklerinin
                      tutuşu azalır. Mevsimsel lastik değişimi, lastik ömrünü
                      uzattığı gibi yakıt verimliliğini de artırabilir.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqAreaOne;
