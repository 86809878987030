import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

const HeaderOne = () => {
  const [active, setActive] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    var offCanvasNav = document.getElementById("offcanvas-navigation");
    var offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        "<span class='mean-expand-class'>+</span>"
      );
    }

    var menuExpand = offCanvasNav.querySelectorAll(".mean-expand-class");
    var numMenuExpand = menuExpand.length;

    function sideMenuExpand() {
      if (this.parentElement.classList.contains("active") === true) {
        this.parentElement.classList.remove("active");
      } else {
        for (let i = 0; i < numMenuExpand; i++) {
          menuExpand[i].parentElement.classList.remove("active");
        }
        this.parentElement.classList.add("active");
      }
    }

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", sideMenuExpand);
    }
    window.onscroll = () => {
      if (window.pageYOffset < 150) {
        setScroll(false);
      } else if (window.pageYOffset > 150) {
        setScroll(true);
      }
      return () => (window.onscroll = null);
    };
  }, []);

  const mobileMenu = () => {
    setActive(!active);
  };
  return (
    <>
      <header className="nav-header header-layout1">
        <div className="header-top">
          <div className="container">
            <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
              <div className="col-auto d-none d-lg-block">
                <div className="header-links">
                  <ul>
                    <li>
                      <i className="fas fa-envelope" />
                      <Link to="mailto:info@altunotolastik.com">
                        info@altunotolastik.com
                      </Link>
                    </li>
                    <li>
                      <i className="fas fa-map-marker-alt" />
                      Tekeler, Adapazarı Kaynarca Yolu No:390/1, 54100
                      Adapazarı/Sakarya
                    </li>
                    <li>
                      <i className="fas fa-clock" />
                      7/24
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-auto">
                <div className="header-links ps-0">
                  <ul>
                    <li>
                      <div className="social-links">
                        <Link to="https://www.facebook.com/profile.php?id=100032739833072">
                          <i className="fab fa-facebook-f" />
                        </Link>
                        <Link to="https://www.instagram.com/altunotolastik">
                          <i className="fab fa-instagram" />
                        </Link>
                        <Link to="https://wa.me/905438783435/?text=Merhaba, ">
                          <i className="fab fa-whatsapp" />
                        </Link>
                        <Link to="mailto:info@altunotolastik.com">
                          <i className="fas fa-envelope" />
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`sticky-wrapper ${scroll && "sticky"}`}>
          {/* Main Menu Area */}
          <div className="menu-area">
            <div className="header-navbar-logo">
              <Link to="/">
                <img
                  src="assets/img/altunoto-lastik-logo-white2.png"
                  alt="Altun Oto Lastik"
                  style={{ width: "90%" }}
                />
              </Link>
            </div>
            <div className="container">
              <div className="row align-items-center justify-content-lg-start justify-content-between">
                <div className="col-auto d-xl-none d-block">
                  <div className="header-logo">
                    <Link to="/">
                      <img
                        src="assets/img/altunoto-lastik-logo-white2.png"
                        alt="Altun Oto Lastik"
                        style={{ width: "90%" }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-auto">
                  <nav className="main-menu d-none d-lg-inline-block">
                    <ul>
                      <li>
                        <NavLink
                          to="/"
                          className={(navData) =>
                            navData.isActive ? "active" : ""
                          }
                        >
                          Anasayfa
                        </NavLink>
                      </li>
                      <li>
                        <Link to="#">Hakkımızda</Link>
                      </li>
                      <li>
                        <Link to="#">Hizmetlerimiz</Link>
                      </li>
                      <li>
                        <NavLink
                          to="/blog"
                          className={(navData) =>
                            navData.isActive ? "active" : ""
                          }
                        >
                          Blog
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/contact"
                          className={(navData) =>
                            navData.isActive ? "active" : ""
                          }
                        >
                          İletişim
                        </NavLink>
                      </li>
                    </ul>
                  </nav>
                  <div className="navbar-right d-inline-flex d-lg-none">
                    <button
                      type="button"
                      className="menu-toggle icon-btn"
                      onClick={mobileMenu}
                    >
                      <i className="fas fa-bars" />
                    </button>
                  </div>
                </div>
                <div className="col-auto ms-auto d-xl-block d-none">
                  <div className="navbar-right-desc call-media-wrap">
                    <div className="icon">
                      <img
                        src="assets/img/icon/phone-1.svg"
                        alt="Altun Oto Lastik"
                      />
                    </div>
                    <div className="navbar-right-desc-details">
                      <Link className="link" to="tel:+905357455407">
                        0(535)745 54 07
                      </Link>
                      <br />
                      <Link className="link" to="tel:+905438783435">
                        0(543)878 34 35
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="logo-bg" />
          </div>
        </div>

        {/* Mobile Menu */}
        <div className={`mobile-menu-wrapper  ${active && "body-visible"}`}>
          <div className="mobile-menu-area">
            <div className="mobile-logo">
              <Link to="/">
                <img src="assets/img/logo.png" alt="Altun Oto Lastik" />
              </Link>
              <button className="menu-toggle" onClick={mobileMenu}>
                <i className="fa fa-times" />
              </button>
            </div>
            <div className="mobile-menu">
              <ul id="offcanvas-navigation">
                <li>
                  <NavLink
                    to="/"
                    className={(navData) => (navData.isActive ? "active" : "")}
                  >
                    Anasayfa
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="#"
                    className={(navData) => (navData.isActive ? "active" : "")}
                  >
                    Hakkımızda
                  </NavLink>
                </li>
                <li>
                  <Link to="#">Hizmetlerimiz</Link>
                </li>
                <li>
                  <NavLink
                    to="/blog"
                    className={(navData) => (navData.isActive ? "active" : "")}
                  >
                    Blog
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contact"
                    className={(navData) => (navData.isActive ? "active" : "")}
                  >
                    İletişim
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderOne;
