import React from "react";

const ProcessAreaOne = () => {
  return (
    <section className="process-area-1 space position-relative">
      <div className="portfolio-shape-img shape-mockup d-lg-block d-none">
        <img
          className="about1-shape-img-1 spin"
          src="/assets/img/normal/about_shape1-2.svg"
          alt="Altun Oto Lastik"
        />
        <img
          className="about1-shape-img-2 spin2"
          src="/assets/img/normal/about_shape1-1.svg"
          alt="Altun Oto Lastik"
        />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="title-area text-center">
              <span className="sub-title">Tamir ve Bakım Süreçlerimiz</span>
              <h2 className="sec-title">
              Güvenilir Araç Lastik Tamirinde En İyi Çözümler
              </h2>
            </div>
          </div>
        </div>
        <div className="row gy-30">
          <div className="col-lg-4 process-card-wrap">
            <div className="process-card">
              <div className="process-card-icon">
                <img src="/assets/img/icon/process-icon-1-1.svg" alt="Altun Oto Lastik" />
              </div>
              <h4 className="process-card-title">Mobil Lastik Tamiri</h4>
              <p className="process-card-text">
                7/24 Adapazarı, Sapanca, Kocaeli ve Kuzey Marmara Oto Yolu civarlarında size hizmete hazırız.
              </p>
            </div>
          </div>
          <div className="col-lg-4 process-card-wrap">
            <div className="process-card process-card-center">
              <div className="process-card-icon">
                <img src="/assets/img/icon/process-icon-1-2.svg" alt="Altun Oto Lastik" />
              </div>
              <h4 className="process-card-title">Profesyonel Ekip ve Ustalarımız</h4>
              <p className="process-card-text">
              Profesyonel ve Deneyimli ekibimiz size en kısa sürede ulaşıp, modern ve teknolojik ekipmanlarımızla en kısa sürede sizi yolunuza uğurlayacaktır.
              </p>
            </div>
          </div>
          <div className="col-lg-4 process-card-wrap">
            <div className="process-card">
              <div className="process-card-icon">
                <img src="/assets/img/icon/process-icon-1-3.svg" alt="Altun Oto Lastik" />
              </div>
              <h4 className="process-card-title">Sakarya İş Makinası Lastikleri</h4>
              <p className="process-card-text">
              Ağır Vasıta araçlarınız için lastik tamiri ve değişim işlemlerini 7/24 hizmet prensibiyle gerçekleştiriyoruz. Aradığınız lastiğin özellikleri ve lastik fiyatları hakkında bilgi alabilirsiniz.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProcessAreaOne;
