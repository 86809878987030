import React from "react";
import { Link } from "react-router-dom";

const FooterAreaOne = () => {
  return (
    <footer
      className="footer-wrapper footer-layout1"
      style={{ backgroundImage: "url(assets/img/bg/footer-bg1-1.png)" }}
    >
      <div className="container">
        <div className="widget-area">
          <div className="row justify-content-between">
            <div className="col-md-6 col-xl-auto">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">Altun Oto Lastik</h3>
                <div className="menu-all-pages-container">
                  <ul className="menu">
                    <li>
                      <Link to="#">Hakkımızda</Link>
                    </li>
                    <li>
                      <Link to="#">Misyon</Link>
                    </li>
                    <li>
                      <Link to="#">Vizyon</Link>
                    </li>
                    <li>
                      <Link to="#">Gizlilik Politikası</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-auto">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">Hizmetlerimiz</h3>
                <div className="menu-all-pages-container">
                  <ul className="menu">
                    <li>
                      <Link to="#">7/24 Yol Yardım</Link>
                    </li>
                    <li>
                      <Link to="#">İş Makinası Lastik Onarımı</Link>
                    </li>
                    <li>
                      <Link to="#">Ağır Vasıta lastik Onarımı</Link>
                    </li>
                    <li>
                      <Link to="#">Traktör ve Arazi Taşıtı Lastik Onarımı</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-auto">
              <div className="widget footer-widget">
                <h3 className="widget_title">İletişim</h3>
                <div className="widget-contact"> 
                  <p>
                  <Link className="link" to="tel:+905438783435">
                      0(543)878-3435
                  </Link>
                  {" "} / {" "}
                  <Link className="link" to="tel:+905357455407">
                      0(535)745-5407
                  </Link>
                  </p>
                  <p>
                    <Link to="mailto:info@altunotolastik.com">info@altunotolastik.com</Link>
                  </p>
                  <p>
                  Tekeler, Adapazarı Kaynarca Yolu <br /> No:390/1, 54100 Adapazarı/Sakarya
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-auto">
              <div className="widget footer-widget widget-newsletter">
                <h3 className="widget_title">E-Bülten</h3>
                <p className="footer-text mb-50">
                  Yeniliklerden ve Size Özel Kampanyalardan İlk Siz Haberdar Olun
                </p>
                <form className="newsletter-form">
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="E-Posta Adresiniz"
                      required=""
                    />
                  </div>
                  <button type="submit" className="btn">
                    <i className="fas fa-arrow-right" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row gy-3 justify-content-md-between justify-content-center">
            <div className="col-auto align-self-center">
              <p className="copyright-text text-center">
                © <Link to="#">Altun Oto Lastik</Link> 2024 | Tüm Hakları Saklıdır
              </p>
            </div>
            <div className="col-auto">
              <div className="footer-links">
                <Link to="#">Gizlilik Politikası</Link>
                <Link to="#">|</Link>
                <Link to="#">Bize Ulaşın</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterAreaOne;
