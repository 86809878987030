import React from "react";
import { Link } from "react-router-dom";

const AboutOne = () => {
  return (
    <div className="about-area-1 space-bottom ">
      <div className="about1-shape-img shape-mockup">
        <img
          className="about1-shape-img-1 spin"
          src="assets/img/normal/about_shape1-2.svg"
          alt="Altun Oto Lastik"
        />
        <img
          className="about1-shape-img-2 spin2"
          src="assets/img/normal/about_shape1-1.svg"
          alt="Altun Oto Lastik"
        />
      </div>
      <div className="container">
        <div className="row gx-60 align-items-center">
          <div className="col-xl-6">
            <div className="about-thumb1 mb-40 mb-xl-0">
              <div className="about-img-1">
                <img src="/assets/img/normal/about_1-1.png" alt="Altun Oto Lastik" />
              </div>
              <div className="about-img-2">
                <img src="/assets/img/normal/about_1-2.png" alt="Altun Oto Lastik" />
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="about-content-wrap">
              <div className="title-area me-xl-5 mb-20">
                <span className="sub-title">Altun Oto Lastik Sakarya</span>
                <h2 className="sec-title">
                  Neden Biz?
                </h2>
                <p className="sec-text">
                Profesyonel ve Deneyimli ekibimiz, modern ve teknolojik ekipmanlarımız ve zaman nakittir prensipli anlayışımız.
                </p>
              </div>
              <div className="row gy-4 justify-content-xl-between justify-content-end align-items-center flex-row-reverse">
                <div className="col-lg-auto">
                  <div className="about-year-wrap">
                    <div
                      className="about-year-mask-wrap"
                      style={{
                        maskImage: "url(assets/img/bg/about_counter-bg1-1.png)",
                      }}
                    >
                      <img src="assets/img/icon/about_icon1-1.svg" alt="Altun Oto Lastik" />
                      <h3 className="about-year-wrap-title">
                        <span className="counter-number">10</span>+
                      </h3>
                      <p className="about-year-wrap-text">
                        Yılı Aşkın Deneyim
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-auto col-lg-6">
                  <div className="checklist">
                    <ul>
                      <li>
                        <i className="fas fa-check-circle" />
                        Sakarya İş Makinesi Lastik Tamiri
                      </li>
                      <li>
                        <i className="fas fa-check-circle" />Sakarya Kamyon Lastik Tamiri
                      </li>
                      <li>
                        <i className="fas fa-check-circle" />
                        Hafif Ticari Araç Lastik Bakım Tamiri{" "}
                      </li>
                      <li>
                        <i className="fas fa-check-circle" />
                        Sakarya 7/24 Yol Yardım Hizmeti
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="btn-wrap mt-20">
                <Link to="#" className="btn style2 mt-xl-0 mt-20">
                  Detay <i className="fas fa-arrow-right ms-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
